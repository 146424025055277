import {Button, Checkbox, FormControlLabel, Radio, styled, TextField} from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import '../../index.css';

export const CustomTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        borderColor: '#c6c6c6', // Colore del bordo
        borderWidth: '2px', // Spessore del bordo
        fontFamily: '"Barlow", sans-serif',
        borderRadius: '20px',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#c6c6c6', // Colore del bordo predefinito
            borderWidth: '2px', // Sovrascrive lo spessore del bordo predefinito
            borderRadius: '20px',
            fontFamily: '"Barlow", sans-serif', // Cambia il font
        },
        '& label': {
            fontFamily: '"Barlow", sans-serif', // Applica il font all'etichetta
        },
    },
    '& label': {
        color: '#8E8E8E', // Cambia il colore dell'etichetta quando è in focus (opzionale)
        fontFamily: '"Barlow", sans-serif',
    },
    // Personalizza ulteriormente altri sottocomponenti se necessario
});

export const CustomButton = styled(Button)({
    backgroundColor: '#d60628', // Imposta il colore di sfondo a rosso
    color: 'white', // Imposta il colore del testo a bianco
    borderRadius: '40px', // Rende i bordi tondeggianti
    minWidth: '25%',
    //fontFamily: '"Edenred", sans-serif',
    fontSize:'3vh',
    fontFamily:'"Barlow", sans-serif',
    '&:hover': {
        backgroundColor: '#d60628', // Cambia il colore di sfondo al passaggio del mouse
    },
});

export const CustomFormControlLabel = styled(FormControlLabel)({
    '& .MuiTypography-root': { // Targetizza il testo della label
        fontFamily: '"Barlow", sans-serif',
    },
});

export const CustomCheckbox = styled(Checkbox)({
    color: 'grey', // Colore di default della checkbox
    '& .MuiSvgIcon-root': { // Applica stili all'icona interna, rendendola tondeggiante
        borderRadius: '50%'
    },
});

export const CustomCheckBoxOutlineBlankIcon = styled(CheckBoxOutlineBlankIcon)({
    border:'3px solid #c6c6c6',
    fill:'transparent',
});

export const CustomCheckBoxIcon = styled(CheckBoxIcon)({
    border:'3px solid #E51576',
    fill:'transparent',
    boxShadow: 'inset white 0px 0px 1px 5px, inset #E51576 0px 0px 10px 10px'
});
